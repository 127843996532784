var prevent = false;

$(document).on({
  mouseenter: function(e) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile && $('.menu-open').length == 0) {
      prevent = true;
      e.preventDefault();
    }
    $('.sub-06').removeClass('hidden');
    $('.other-menu').removeClass('hidden');
    $('.menu06-selector').removeClass('closed');
    $('.menu-item-container').addClass('menu-open');
    $('.menu-item-container').addClass('animate');
    $('.js-menu-wrapper')[0].style.height = `48px`;
    $('.js-menu-wrapper')[0].style.overflow = 'visible';
    if (prevent) {
      e.preventDefault();
    }
  },
  mouseleave: function(e) {
    $('.submenu').addClass('hidden');
    $('.menu-item-container').removeClass('animate');
    $('.other-menu').addClass('hidden');
    $('.menu1-selector').remove('closed');
    var toClose =$('.mm-menu__primary__start')[0].dataset.toClose;
    $( '.sub-' + toClose).addClass('hidden');
    var menuSelector = '.menu' + toClose + '-selector';
    $(menuSelector).addClass('closed');
    $('.menu-item-container').removeClass('menu-open');
  }
}, ".mm-menu__primary__start");

$(document).on({
  click: function(e) {
    e.currentTarget.querySelector('.image-video-component').classList.add('-focus');
  }
}, ".pd-page__content .circular-banners .experience-component");

$(document).on({
  click: function(e) {
    if (prevent) {
      e.preventDefault();
      prevent = false;
    }
  }
}, ".mm-menu__primary__start");

$(document).on({
  click: function(e) {
    if (this.classList.contains('-unclickable')) {
      e.preventDefault();
      var id = this.id;
      var selector = '.sub-' + id;
      var menuSelectorPrevious = '.menu' + this.parentNode.parentNode.dataset.previousparent + '-selector';
      var menuSelector = '.menu' + id + '-selector';
      var selectorParent = '.sub-' + this.parentNode.parentNode.dataset.previousparent;
      var container = $('.mm-menu__primary__start');
      container[0].dataset.toClose = id;
      $(selectorParent).addClass('hidden');
      $(menuSelectorPrevious).addClass('closed');
      $(menuSelector).removeClass('closed');

      $(selector).removeClass('hidden');
    }
  }
}, ".main-section");

$(document).ready(function() {
  if($('.breadcrumb-item.first-element').length > 0) {
    var first = $('.breadcrumb-item.first-element')[0].children[0].dataset.parentcategory;
    var selector = $('.mm-menu__primary__item');

    for (var i = 0; i < selector.length; i++) {
      if (selector[i].dataset && selector[i].dataset.categoryId == first) {
        selector[i].classList.add('-active');
      }
    }
  }
});